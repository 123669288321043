<template>
  <div class='bidding'>
    <biddingHero/>
    <biddingElectronic/>
  </div>
</template>

<script>
  export default {
    name: 'Bidding',
    components: {
      BiddingHero: () => import('@/components/pages/bidding/BiddingHero.vue'),
      BiddingElectronic: () => import('@/components/pages/bidding/BiddingElectronic.vue'),
    }
  }
</script>